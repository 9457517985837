<script>
	import Image from '$lib/Components/Layout/Image/Image.svelte';

	let {
		backgroundColor = 'white',
		backgroundImage = '',
		title = '',
		id = '',
		style = '',
		imageColumn = false,
		columnPosition = 'right',
		imageColumnAlt = '',
		class: className = '',
		innerClass = '',
		imageColumnClass = '',
		backgroundImageClass = '',
		loading = null,
		children,
	} = $props();
</script>

<section
	class:bg-indow-white={backgroundColor == 'white'}
	class:bg-indow-blue={backgroundColor == 'blue'}
	class:bg-indow-grey-light={backgroundColor == 'grey-light'}
	class:bg-indow-grey={backgroundColor == 'grey'}
	class:bg-indow-grey-dark={backgroundColor == 'grey-dark'}
	class:two-column={imageColumn}
	class:has-background-image={backgroundImage}
	class:!p-0={backgroundImage}
	class:content-grid={!imageColumn}
	class:!grid-cols-2={imageColumn}
	class:py-10={!imageColumn && !backgroundImage}
	{id}
	class={[
		'full-width isolate z-10 grid flex-auto place-items-center content-start [&_>:last-child]:mb-0 -:[&_>picture:not(:last-child)>img]:mb-4',
		className ?? '',
	].join(' ')}
	style={style ? style : ''}
>
	{#if backgroundImage}
		<div
			class="full-width absolute z-0 col-span-full row-span-full h-full w-full [&_.picture]:contents"
		>
			<Image
				src={backgroundImage}
				class={[
					'col-span-full row-span-full h-full !max-w-full object-cover',
					backgroundImageClass,
				].join(' ')}
				{loading}
			/>
		</div>
		<div
			class={[
				'inner z-10 col-span-full row-span-full grid h-full w-full justify-items-center py-10',
				innerClass,
			].join(' ')}
			class:md:!col-span-1={imageColumn}
			class:content-grid={imageColumn}
			class:md:!col-start-2={columnPosition == 'left'}
		>
			{#if title}
				<h2>
					{title}
				</h2>
			{/if}
			{@render children?.()}
		</div>
	{:else if imageColumn}
		<div
			class={[
				'inner z-10 col-span-full row-span-full grid w-full justify-items-center py-12',
				innerClass,
			].join(' ')}
			class:md:!col-span-1={imageColumn}
			class:content-grid={imageColumn}
			class:md:!col-start-2={columnPosition == 'left'}
		>
			{#if title}
				<h2>
					{title}
				</h2>
			{/if}
			{@render children?.()}
		</div>
		<div
			class:md:col-start-1={columnPosition == 'left'}
			class="relative col-span-full col-start-1 grid h-full w-full md:!col-span-1 md:col-start-2 md:row-span-full [&_.picture]:contents"
		>
			<Image
				src={imageColumn}
				alt={imageColumnAlt}
				class={['h-full w-full max-w-full', imageColumnClass].join(' ')}
				{loading}
			/>
		</div>
	{:else}
		{#if title}
			<h2>
				{title}
			</h2>
		{/if}
		{@render children?.()}
	{/if}
</section>

<style>
	/* adding "section :global('class') will force the formating from this onto anything put into the slot of this component*/
	/* containers */
	section {
		width: 100%;
		align-items: center;
		position: relative;
	}

	.image-column-left {
		grid-column-start: 2;
	}

	/* other characteristics */

	.has-background-image {
		grid-template-rows: 1fr;
	}

	@media only screen and (max-width: 768px) {
		.two-column {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: auto auto;
			justify-items: center;
			padding: 0;
		}
		.two-column .inner {
			grid-row: 2;
		}
	}
</style>
